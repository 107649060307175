<template>
	<!-- 测试-建用例 -->
	<div>
		<GModule class="container">
			<GTitle :name="updateId ? '修改用例' :'建用例'" style="margin: 20px;"></GTitle>
			<div class="detail-all">
				<a-form
					class="need-box"
				    :model="formState"
				    :label-col="{ span: 8}"
				    :wrapper-col="{ span: 10}"
				    autocomplete="off"
					:scrollToFirstError='true'
				    @finish="onFinish">
					<a-form-item
						label="用例类型" 
						name="case_type" 
						:colon= "false" 
						:rules="[{ required: false }]">
					    <a-dropdown>
					        <template #overlay>
					            <a-menu selectable @click="handleManClick">
					    			<a-menu-item v-for="(mitem,mindex) in manArr" :key="mindex">{{mitem}}</a-menu-item>
					            </a-menu>
					        </template>
					        <a-button>
					    		{{manArr[manIndex]}}
					            <DownOutlined />
					        </a-button>
					    </a-dropdown>
					</a-form-item>
					<a-form-item
						label="用例摘要" 
						name="describe" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入用例摘要' }]">
					    <a-textarea placeholder="用例摘要" style="height: 150px;" v-model:value="formState.describe" />
					</a-form-item>
					<a-form-item
						label="前置条件" 
						name="preconditions" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入前置条件' }]">
					    <a-textarea placeholder="前置条件" style="height: 150px;" v-model:value="formState.preconditions" />
					</a-form-item>
					<a-form-item
						label="操作步骤" 
						name="steps" 
						:colon= "false" 
						:rules="[{ required: false}]">
					    <a-table
					    	:columns="columnsHe" 
					    	:data-source="data" 
					    	:scroll="{ x: 300}" 
					    	bordered
					    	:pagination="false">


							<template #headerCell="{title, column}">
								<template v-if="column.key == 'del'">
									<plus-outlined @click="addClick" />
								</template>
							</template>

							<template #bodyCell="{ column,index,record}">
								<template v-if="column.title === '步骤'">
									{{record.index}}
								</template>
								<template v-if="column.dataIndex === 'content'">
									 <a-input v-model:value="record.content" />
								</template>
					    		<!-- <template v-if="column.key === 'operation'">
					    			<plus-outlined @click="addClick(index)"/>
					    		</template> -->
								<template v-if="column.key === 'del'">
									<delete-outlined v-if="index > 0" @click="delClick(index)"/>
								</template>
							</template>
					    </a-table>
					</a-form-item>
					<a-form-item 
						label="预期结果" 
						name="results" 
						:colon= "false" 
						:rules="[{ required: true, message: '请输入预期结果' }]">
					    <a-textarea placeholder="预期结果" style="height: 150px;" v-model:value="formState.results" />
					</a-form-item>
					<a-form-item
						label="需求模块" 
						name="describe" 
						:colon= "false" 
						:rules="[{ required: false }]">
					    <a-breadcrumb 
							separator=">" 
							style="width: 400px;margin-bottom: 14px;">
								<a-breadcrumb-item v-for="(item,index) in modularsArr" 
									:key="index">{{item}}</a-breadcrumb-item>
					     </a-breadcrumb>
						 <!-- <a-breadcrumb
						 	separator=">" 
						 	style="width: 400px;margin-bottom: 14px;" 
						 	v-else>
						     <a-breadcrumb-item 
						 		v-for="(item,index) in selectedMemu" 
						 		:key="index">{{item.name}}</a-breadcrumb-item>
						  </a-breadcrumb> -->
					</a-form-item>
					<a-form-item
						label="需求描述" 
						name="describe" 
						:colon= "false" 
						:rules="[{ required: false }]">
					    <a-textarea placeholder="需求描述" style="height: 150px;" v-model:value="mInfo.describe" disabled/>
					</a-form-item>
					<a-form-item
						label="参数/逻辑说明" 
						name="explain" 
						:colon= "false" 
						:rules="[{ required: false }]">
					    <a-textarea placeholder="参数/逻辑说明" style="height: 90px;" v-model:value="mInfo.explain" disabled/>
					</a-form-item>
					<a-form-item
						label="需求图片" 
						name="imgList" 
						:colon= "false" 
						:rules="[{ required: false }]">
					    <div class="img-scroll" @click="openSwiper()">
					    	<a-image :preview="false" :width="90" :src="itm" v-for="(itm,ind) in mInfo.imgArr" :key="ind"/>
					    </div>
					</a-form-item>
					<a-form-item  :wrapper-col="{ offset:10 }">
					  <a-button class="btn clo1" html-type="submit">提交</a-button>
					  <a-button class="btn clo6" @click="cancel()">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</GModule>	
		
	</div>
</template>

<script>
	export default{
		name:'TheTestCaseAdd',
		data(){
			return{
				formState :{
					case_type:'',
					describe:'',
					preconditions:'',
					demand_id:0,
					steps:[],
					results:''
				},
				columnsHe: [{
				    title: '步骤',
				    width: 60,
				    dataIndex: 'index',
				    fixed: 'left'
				  },{
				    title: '操作',
					dataIndex: 'content',
					width: 300
				},{
					title: '',
					key: 'del',
					fixed: 'right',
					width: 40
				}],
				data: [
					{index:1,content:''}
				],
				manArr:{} ,// 用例类型列表
				manIndex:'main',
				mInfo:{} ,// 需求模块详情
				updateId:0 ,// 修改的id
				modularsArr:[] // 修改用的需求模块
			}
		},
		computed: {
			// selectedMemu(){
			// 	return this.$store.state.selectedMemu
			// },
			// mId(){
			// 	var id = 0;
			// 	if(this.selectedMemu&&this.selectedMemu.length)id=this.selectedMemu[this.selectedMemu.length - 1].id;
			// 	return id
			// }
		},
		watch:{
			// selectedMemu(){
			// 	console.log('选中的模块2',this.selectedMemu)
			// },
		},
		created() {
			this.getData();
		},
		methods:{
			async getData(){
				this.updateId = this.$route.query.id || 0
				this.demandId = this.$route.query.demandId || 0
				if(this.updateId){ // 修改
					// 根据列表id请求修改的详情
					let r = await this.$utils.api.post({
						url:'/cases/getRow',
						data:{
							ids:this.updateId
						},
						result:1
					})
					if(r.code == 1){
						this.manIndex = r.data.case_type
						this.formState = {
							case_type: r.data.case_type,
							describe: r.data.describe,
							preconditions: r.data.preconditions,
							demand_id: r.data.demand.id,
							steps: r.data.steps,
							results: r.data.results
						}
						this.data = r.data.steps
						r.data.demand.imgArr = r.data.demand.images.length > 0 ? r.data.demand.images.split(',') : []
						this.mInfo = r.data.demand
						this.modularsArr = r.data.demand.modulars
					}
				}else if(this.demandId){ // 建用例
					// 根据模块id请求需求详情
					let res1 = await this.$utils.api.post({
						url:'/demand/getRow',
						data:{
							ids:this.demandId
						},
						result:1
					})
					if(res1.code == 1){
						res1.data.imgArr = res1.data.images.length > 0 ? res1.data.images.split(',') : []
						this.mInfo = res1.data
						this.modularsArr = res1.data.modulars
					}
				}
				// 获取用例类型
				let res = await this.$utils.api.get({
					url:'/cases/setting',
					result:1
				})
				if(res.code == 1){
					this.manArr = res.data.caseTypeList
				}
			},
			handleManClick(e){
				this.manIndex = e.key
			},
			openSwiper(){
				// 弹出图片列表
				this.$store.commit('setSwiperArr',this.mInfo.imgArr)
				this.$store.commit('setSwiper',1)
			},
			addClick(){
				// 添加操作步骤
				let obj = {
					index:(this.data.length+1),
					content:''
				}
				this.data.push(obj)
			},
			delClick(ind){
				// 删除操作步骤
				this.data.splice(ind,1)
				for(let i in this.data){
					this.data[i].index = parseInt(i)+1
				}
			},
			async onFinish(e){
				let ok = false
				for(let i in this.data){
					if(this.data[i].content == ''){
						ok = false
					}else{
						ok = true
					}
				}
				if(!ok){
					this.$utils.msgWar('操作步骤不允许有空值')
					return
				}
				let info,url;
				if(this.updateId){
					// 修改
					url = '/cases/edit'
					info = {
						ids:this.updateId,
						'row':{
							'case_type': this.manIndex,
							'describe':this.formState.describe,
							'preconditions':this.formState.preconditions,
							'steps': this.data,
							'results':this.formState.results
						}
					}
				}else{
					// 添加
					url = '/cases/add'
					info = {
						'row':{
							'case_type': this.manIndex,
							'describe':this.formState.describe,
							'preconditions':this.formState.preconditions,
							'demand_id':this.demandId,
							'steps': this.data,
							'results':this.formState.results
						}
					}
				}
				
				let res = await this.$utils.api.post({
					url:url,
					data:info,
					result:1
				})
				if(res.code == 1){
					this.$utils.msgSuc('提交成功')
					this.cancel()
				}
			},
			cancel(){
				// 取消
				this.$router.back()
			}
		}
	}
</script>

<style lang="scss" scoped>
.container{
	margin: 20px;
	padding: 0 0 50px 0;
	overflow: hidden;
	.detail-all{
		padding: 30px 0;
		.need-box{
			.btn{
				margin-right: 10px;
			}
			.img-scroll{
				height: 90px;
				overflow-x: auto;
				overflow-y: hidden;
				display: flex;
				align-items: center;
				&::-webkit-scrollbar {
					height: 0px; // 控制滚动条
				}
				&::-webkit-scrollbar-thumb{
					background-color: #a5ddff; // 滚动条颜色
				}
				&:hover{
					&::-webkit-scrollbar {
						height: 8px;

					}
				}
			}
		}
	}
}
</style>